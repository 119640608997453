import {
    Button,
    Container,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    Switch,
    TextField,
    Typography
} from "@mui/material";
import {Form, FormikProvider, useFormik} from "formik";
import Page from "layout/Page";
import {useSnackbar} from "notistack";
import React, {useCallback, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router";
import {useSearchParams} from "react-router-dom";
import {SET_CATEGORIES, SET_ROLES} from "store/actions";
import axiosClient from "utils/axiosClient";
import MainCard from "ui-component/cards/MainCard";
import * as Yup from "yup";
import LoadingScreen from "components/loader/LoadingScreen";
import {passwordRegExp, removeObjectValues} from "utils/FormatFuncions";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import countries from "data/countries";
import Countries from "data/countries";
import Roles from "data/roles";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {DesktopDatePicker} from "@mui/x-date-pickers/DesktopDatePicker";
import {MRadio, MRadioMaritalStatus, MSelectCountries, MTextField} from "lib";
import activeStatusText from "data/ActiveStatusText";
// import countries from "data/countries";
//import countries from "../../data/countries.js";
import {Country, State, City} from "country-state-city";
import MaritalStatusText from "data/MaritalStatusText";
import {consoleError} from "utils/consoleData";

export default function CreateCustomer() {
    const {contracts, technicians, roles, categories} = useSelector((state) => state?.admin);
    const Countries = Country.getAllCountries();

    const [loading, setLoading] = useState(false);

    const [technicianData, setTechnicianData] = useState(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const [lat, setLat] = useState(17.385044);
    const [long, setLong] = useState(78.486671);
    const [showPassword, setShowPassword] = useState(false);
    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handlePassword = () => {
        setShowPassword((prevValue) => !prevValue);
    };

    // if (navigator.geolocation) {
    //     navigator.geolocation.getCurrentPosition(successFunction, errorFunction);
    // } else {
    //     alert(
    //         "It seems like Geolocation, which is required for this page, is not enabled in your browser. Please use a browser which supports it."
    //     );
    // }
    // function successFunction(position) {
    //     setLat(position.coords.latitude);
    //     setLong(position.coords.longitude);
    //     // console.log("Your latitude is :" + lat + " and longitude is " + long);
    // }

    // function errorFunction(position) {
    //     //   setLat(position.coords.latitude);
    //     //  setLong(position.coords.longitude);
    //     // console.log("Your latitude is :", position);
    // }

    useEffect(() => {
        if (window.location.href.includes("edit")) {
            setLoading(!false);
            axiosClient
                .get(`admin/customerid?CustomerId=${searchParams.get("edit")}&pageNo=1&size=100`)
                .then(async (res) => {
                    if (res.status == 200) {
                        //  console.log("bbbbbbbb", res);
                        const {data} = res.data;
                        if (data?.length) {
                            setTechnicianData(res.data?.data[0]);
                            setLoading(!!false);
                        }
                    }
                })
                .catch((err) => {
                    consoleError("error", err);
                    setLoading(!!false);
                });
            // const value = JSON.parse(window.localStorage.getItem("adminedituser"));

            //   setTechnicianData(value);
            //
        }
    }, [searchParams]);

    const handleTechnician = (payload) => {
        if (window.location.href.includes("edit")) {
            setLoading(!false);
            axiosClient
                .put(`/admin/updatecustomerdetails?CustomerId=${searchParams.get("edit")}`, payload)
                .then((res) => {
                    enqueueSnackbar(res.data?.message, {variant: "success"});
                    setTimeout(() => {
                        navigate("/dashboard/customers");
                    }, 1000);
                    setLoading(!!false);
                })
                .catch((err) => {
                    enqueueSnackbar(err?.message, {variant: "error"});
                    consoleError("error", err);
                    setLoading(!!false);
                });
        } else {
            setLoading(!false);
            axiosClient
                .post("/admin/addcustomer", payload)
                .then((res) => {
                    enqueueSnackbar(res.data?.message, {variant: "success"});
                    setLoading(!!false);
                    setTimeout(() => {
                        navigate("/dashboard/customers");
                    }, 1000);
                })
                .catch((err) => {
                    consoleError("error", err);
                    enqueueSnackbar(err?.data, {variant: "error"});
                    setLoading(!!false);
                });
        }
    };

    const handleChange = (newValue) => {
        formik.setFieldValue("dateOfBirth", newValue);
        // setValue(newValue);
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            firstName: technicianData?.firstName ?? "",
            lastName: technicianData?.lastName ?? "",
            passwordHash: "",
            userName: technicianData?.userName ?? "",
            activeStatus: technicianData?.activeStatus ?? true,
            mobile: technicianData?.mobile ?? "",
            email: technicianData?.email ?? "",
            address: technicianData?.address ?? "",
            // longitude: technicianData?.longitude ?? lat,
            // latitude: technicianData?.latitude ?? long,
            latitude: 78.4498688,
            longitude: 17.4096384,
            imageURL: technicianData?.imageURL ?? "https://i.pravatar.cc/150?img=3",
            /* added new values for new payload */
            roleId: Roles[5]?.id,
            dateOfBirth: technicianData?.dateOfBirth ?? "",
            country: technicianData?.country ?? "",
            city: technicianData?.city ?? "",
            contactPerson: technicianData?.contactPerson ?? "",
            contactMobileNo: technicianData?.contactMobileNo ?? "",
            profession: technicianData?.profession ?? "",
            workName: technicianData?.placeOfWorkName ?? "",
            workAddress: technicianData?.placeOfWorkAddress ?? "",
            nationality: technicianData?.nationality ?? "",
            maritalStatus: technicianData?.maritalStatus ?? 1,
            //     contactMobile: technicianData?.contactMobile ?? "",
            contactEmail: technicianData?.contactEmail ?? ""
        },
        validationSchema: Yup.object().shape({
            firstName: Yup.string("Enter your First Name").trim().nullable(true).required("First Name is required"),
            lastName: Yup.string("Enter your Last Name").trim().nullable(true).required("Last Name is required"),
            userName: Yup.string("Enter your User Name").trim().nullable(true).required("User Name is required"),
            mobile: Yup.string().matches(phoneRegExp, "Mobile Number is not valid").required("Mobile Number is required"),
            email: Yup.string("Enter your Email").email("Please enter Valid Email").trim().nullable(true).required("Email is required"),
            address: Yup.string("Enter your Address").trim().nullable(true).required("Address is required"),
            passwordHash: Yup.string()
                .trim()
                .nullable(true)
                .min(8)
                .required("Please Enter your password")
                .matches(
                    passwordRegExp,
                    "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
                ),
            /* added new values for new payload */
            country: Yup.string().trim().nullable(true).required(),
            city: Yup.string().trim().nullable(true).required(),

            dateOfBirth: Yup.date().nullable(true).required(),
            contactPerson: Yup.string().trim().nullable(true).required(),
            contactMobileNo: Yup.string().trim().matches(/^\d+$/, "Accepts Only Numbers").length(10).required(),
            profession: Yup.string().trim().nullable(true).required(),
            workName: Yup.string().trim().nullable(true).required(),
            workAddress: Yup.string().trim().nullable(true).required(),
            nationality: Yup.string().trim().nullable(true).required(),
            //  maritalStatus: 0,
            //    contactMobile: Yup.string().trim().matches(/^\d+$/, "Accepts Only Numbers").length(10).required(),
            contactEmail: Yup.string().trim().nullable(true).required(),
            maritalStatus: Yup.bool().required(),

            activeStatus: Yup.bool().required()
        }),
        onSubmit: (values, {resetForm}) => {
            //   console.log(values);
            const payload = removeObjectValues(values);
            handleTechnician(payload);
        }
    });

    const handleCountryChange = useCallback((countryname) => {
        // const newValues = Countries?.length > 0 && Countries.filter((item) => item?.name == formik.values.country);
        //  console.log("gggggggg", countryname);
        //  setNewCountries(countryname);
    }, []);

    //  console.log(formik.values.activeStatus, technicianData?.activeStatus);

    return (
        <Container maxWidth="sm">
            <MainCard title={searchParams.get("edit") ? "Update Customer" : "Create Customer"} border={false}>
                {loading ? (
                    <LoadingScreen />
                ) : (
                    <FormikProvider value={formik}>
                        <Form noValidate onSubmit={formik.handleSubmit}>
                            <MTextField label="First Name" name="firstName" type="text" />
                            <MTextField label="Last Name" name="lastName" />
                            <MTextField label="User Name" name="userName" />
                            <MTextField label="Mobile" name="mobile" type="tel" />
                            <MTextField label="Email" name="email" type={"email"} />
                            <FormControl
                                margin="normal"
                                fullWidth
                                error={formik.touched.passwordHash && Boolean(formik.errors.passwordHash)}
                                variant="outlined"
                            >
                                <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-password"
                                    type={showPassword ? "text" : "password"}
                                    //  value={values.password}
                                    // onChange={handleChange("password")}
                                    {...formik.getFieldProps("passwordHash")}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handlePassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label="Password"
                                />
                                {formik.touched.passwordHash && Boolean(formik.errors.passwordHash) && (
                                    <FormHelperText>{formik.touched.passwordHash && formik.errors.passwordHash}</FormHelperText>
                                )}
                            </FormControl>
                            <MTextField label="Address" name="address" />
                            {/* <MRadio
                                name="maritalStatus"
                                label="Are you Married ?"
                                legend={"Are you Married ?"}
                                options={MaritalStatusText}
                                value={formik.values.maritalStatus}
                            /> */}
                            <MRadioMaritalStatus
                                name="maritalStatus"
                                label="Are you Married ?"
                                legend={"Are you Married ?"}
                                options={MaritalStatusText}
                                value={formik.values.maritalStatus}
                            />
                            {/* added new values for new payload */}
                            <MSelectCountries
                                name="country"
                                label="Country"
                                options={Countries}
                                handleCountryChange={handleCountryChange}
                            />
                            {formik.values.country && <MTextField name="city" label="City" />}
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DesktopDatePicker
                                    label="Date of Birth"
                                    inputFormat="MM/dd/yyyy"
                                    value={formik.values.dateOfBirth}
                                    //  {...formik.getFieldProps("dateOfBirth")}
                                    onChange={handleChange}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            fullWidth
                                            type={"date"}
                                            margin="normal"
                                            error={formik.touched.dateOfBirth && Boolean(formik.errors.dateOfBirth)}
                                            helperText={formik.touched.dateOfBirth && formik.errors.dateOfBirth}
                                        />
                                    )}
                                />
                            </LocalizationProvider>
                            <MTextField label="Contact Person" name="contactPerson" />
                            <MTextField label="Contact Mobile" name="contactMobileNo" type="tel" />
                            <MTextField label="Contact Email" name="contactEmail" type="email" />
                            <MTextField label="Profession" name="profession" />
                            <MTextField label="Work Place" name="workName" />
                            <MTextField label="Work Address" name="workAddress" />
                            <MTextField label="Nationality" name="nationality" />
                            {/* <MTextField label="Contact Mobile" name="contactMobile" type="tel" /> */}

                            <MRadio
                                name="activeStatus"
                                label="Status"
                                legend={"Status"}
                                options={activeStatusText}
                                value={formik.values.activeStatus}
                            />
                            <Grid container justifyContent="flex-end" spacing={3}>
                                <Grid item xs={12} md={6}>
                                    <Button
                                        type="button"
                                        variant="outlined"
                                        fullWidth
                                        sx={{mt: 3, mb: 2}}
                                        disableElevation
                                        color="secondary"
                                        onClick={() => navigate(-1)}
                                    >
                                        Back to List
                                    </Button>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        fullWidth
                                        sx={{mt: 3, mb: 2}}
                                        disableElevation
                                        color="secondary"
                                    >
                                        submit
                                    </Button>
                                </Grid>
                            </Grid>
                        </Form>
                    </FormikProvider>
                )}
            </MainCard>
        </Container>
    );
}
