const OwnCarStatus = [
    {
        name: "No",
        value: false
    },
    {
        name: "Yes",
        value: true
    }
];

export default OwnCarStatus;
