/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from "react";
import {DataGrid} from "@mui/x-data-grid";
import {makeStyles} from "@mui/styles";
import TdTableActionMenu from "./TdTableActionMenu";
import {IconButton} from "@mui/material";

const useStyles = makeStyles((theme) => ({
    tableContainer: {
        width: "100%",
        flex: 1,
        alignSelf: "stretch",
        "&.MuiDataGrid-root": {
            border: "1px solid #ddd",
            fontSize: "1rem"
        },
        "& .MuiDataGrid-columnHeaderTitleContainer": {
            padding: 0
        },
        "& .MuiDataGrid-columnHeaderTitle": {
            color: theme.palette.text.dark
        },
        "& .inActiveRow": {
            background: "#000000",
            borderRadius: 4
        },
        "& .MuiDataGrid-cell": {
            outline: "inherit!important"
        },
        "& .MuiDataGrid-columnHeaders": {
            //background: theme.palette.primary.light
        },
        "& .MuiDataGrid-columnSeparator": {
            display: "none"
        }
    }
}));

export const TdTable = (props) => {
    const {
        tableContainerStyle,
        columns,
        getRowActions,
        actionIconStyle,
        moreVertColor,
        miCheckboxSelection,
        isMiCheckboxEnabled,
        onMiSelectionChange,
        miSelectedRowIds,
        getRowId,
        toolbar,
        customToolbar,
        hideFooter = true,
        ...rest
    } = props;
    const [colDefs, setColDefs] = useState(columns);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const classes = useStyles({});

    useEffect(() => {
        const updatedDefs = [...columns];

        if (getRowActions) {
            updatedDefs.push({
                headerName: "",
                field: "",
                sortable: false,
                disableColumnMenu: true,
                align: "right",
                renderCell: ({row}) => (
                    <IconButton onClick={(e) => handleActionsIconSelect(e, row)} style={{cursor: "pointer", color: moreVertColor}}>
                        {row.id === selectedActionRow?.id && renderActions()}
                    </IconButton>
                )
            });
        }
        setColDefs(updatedDefs);
    }, [miSelectedRowIds, columns]);

    const handleClose = () => {
        setAnchorEl(null);
    };

    const renderActions = () => {
        if (!selectedActionRow) {
            return;
        }
        const rowActions = getRowActions(selectedActionRow);
        if (!rowActions.length) {
            return;
        }

        return (
            <TdTableActionMenu
                open={open}
                selectedActionRow={selectedActionRow}
                rowActions={rowActions}
                handleClose={handleClose}
                anchorEl={anchorEl}
            />
        );
    };

    const handleActionsIconSelect = (e, row) => {
        if (row?.id === selectedActionRow?.id) {
            setSelectedActionRow(null);
            setAnchorEl(null);
        } else {
            setSelectedActionRow(row);
            setAnchorEl(e.currentTarget);
        }
        e.stopPropagation();
    };

    const renderDataGrid = () => {
        if (!columns) {
            return null;
        }
        return (
            <div style={{...tableContainerStyle, flexGrow: 1}} data-testid="MiTable">
                <DataGrid
                    className={classes.tableContainer}
                    {...rest}
                    columns={colDefs}
                    getRowId={getRowId}
                    disableSelectionOnClick
                    hideFooter={hideFooter}
                    autoHeight={true}
                />
            </div>
        );
    };

    return renderDataGrid();
};
export default TdTable;
