import {Button} from "@mui/material";
import axios from "axios";
import React, {useCallback, useEffect, useState, Fragment} from "react";
import {read, utils, writeFileXLSX} from "xlsx";

const ExportButton = ({arrayData, fileName}) => {
    const [pres, setPres] = useState([]);

    useEffect(() => {
        if (arrayData?.length > 0) {
            setPres(arrayData);
        }
    }, [arrayData]);

    const exportFile = useCallback(() => {
        const ws = utils.json_to_sheet(pres);
        const wb = utils.book_new();
        utils.book_append_sheet(wb, ws, "sheet1");
        writeFileXLSX(wb, fileName);
    }, [fileName, pres]);

    return (
        <Fragment>
            {arrayData?.length > 0 && (
                <Button variant="contained" color="primary" onClick={exportFile}>
                    Export
                </Button>
            )}
        </Fragment>
    );
};

export default ExportButton;
