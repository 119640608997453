const activeStatusText = [
    {
        name: "In Active",
        value: false
    },
    {
        name: "Active",
        value: true
    }
];

export default activeStatusText;
