// assets
import {IconDashboard} from "@tabler/icons";
import {userRole} from "utils/FormatFuncions";

// constant
const icons = {IconDashboard};

const dashboard = {
    id: "dashboard",
    title: "Dashboard",
    type: "group",
    children: [
        {
            id: "default",
            title: "Dashboard",
            type: "item",
            //  url: "/dashboard/default",
            url: "/",
            icon: icons.IconDashboard,
            breadcrumbs: false,
            display: userRole?.roleId === 1 ? true : false
        }
    ]
};

export default dashboard;
