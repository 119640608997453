// action - customization reducer
export const SET_MENU = "@customization/SET_MENU";
export const MENU_TOGGLE = "@customization/MENU_TOGGLE";
export const MENU_OPEN = "@customization/MENU_OPEN";
export const SET_FONT_FAMILY = "@customization/SET_FONT_FAMILY";
export const SET_BORDER_RADIUS = "@customization/SET_BORDER_RADIUS";

// action - auth
export const SET_AUTH = "AUTH";
export const SET_CONTRACTS = "CONTRACTS";
export const SET_TECHNICIANS = "TECHNICIANS";
export const SET_ROLES = "ROLES";
export const SET_ADMINS = "ADMINS";
export const SET_CUSTOMERS = "CUSTOMERS";
export const SET_USERADDRESS_LIST = "USERADDRESS_LIST";
export const SET_ADDRESS_TYPE = "ADDRESS_TYPE";
export const SET_DASHBOARD = "DASHBOARD";
export const SET_CATEGORIES = "CATEGORIES";
export const SET_LANGUAGE = "LANGUAGE";
export const SET_TASKS = "TASKS";
export const SET_OPERATION_MANAGERS = "OPERATION_MANAGERS";
export const SET_DASHBOARD_VALUES = "DASHBOARD_VALUES";
export const SET_DASHBOARD_COUNT = "DASHBOARD_COUNT";
export const SET_CONFIGS = "CONFIGS";
export const SET_COUNTRIES = "COUNTRIES";
export const SET_CITIES = "CITIES";
