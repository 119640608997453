import {useState, useRef, useEffect} from "react";

import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

// material-ui
import {useTheme} from "@mui/material/styles";
import {
    Avatar,
    Box,
    Card,
    CardContent,
    Chip,
    ClickAwayListener,
    Divider,
    Grid,
    InputAdornment,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    OutlinedInput,
    Paper,
    Popper,
    Stack,
    Switch,
    Typography
} from "@mui/material";

// third-party
import PerfectScrollbar from "react-perfect-scrollbar";

// project imports
import MainCard from "ui-component/cards/MainCard";
import Transitions from "ui-component/extended/Transitions";
import User1 from "assets/images/users/user-round.svg";

// assets
import {IconLogout, IconSearch, IconSettings, IconUser} from "@tabler/icons";
import GTranslateIcon from "@mui/icons-material/GTranslate";
import {SET_LANGUAGE} from "store/actions";
import {useTranslation} from "react-i18next";

// ==============================|| PROFILE MENU ||============================== //

const ProfileSection = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const {t, i18n} = useTranslation();
    const customization = useSelector((state) => state.customization);
    const navigate = useNavigate();

    const [sdm, setSdm] = useState(true);
    const [value, setValue] = useState("");
    const [notification, setNotification] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [open, setOpen] = useState(false);
    /**
     * anchorRef is used on different componets and specifying one type leads to other components throwing an error
     * */
    const anchorRef = useRef(null);
    const handleLogout = async () => {
        await window.localStorage.removeItem("jwt");
        await window.localStorage.removeItem("user");
        navigate("/login");
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const handleListItemClick = (event, index, route = "") => {
        setSelectedIndex(index);
        handleClose(event);

        if (route && route !== "") {
            navigate(route);
        }
    };
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    const handleLangauage = async () => {
        await setOpen(false);
        await i18n.changeLanguage(customization?.language ? "en" : "ar");
        await window.localStorage.setItem("language", JSON.stringify(!customization?.language ? "ar" : "en"));
        await window.localStorage.setItem("direction", JSON.stringify(!customization?.language));
        await dispatch({type: SET_LANGUAGE, language: !customization?.language});
    };

    return (
        <>
            <Chip
                sx={{
                    height: "48px",
                    alignItems: "center",
                    borderRadius: "27px",
                    transition: "all .2s ease-in-out",
                    borderColor: theme.palette.primary.light,
                    backgroundColor: theme.palette.primary.light,
                    '&[aria-controls="menu-list-grow"], &:hover': {
                        borderColor: theme.palette.primary.main,
                        background: `${theme.palette.primary.main}!important`,
                        color: theme.palette.primary.light,
                        "& svg": {
                            stroke: theme.palette.primary.light
                        }
                    },
                    "& .MuiChip-label": {
                        lineHeight: 0
                    }
                }}
                icon={
                    <Avatar
                        src={User1}
                        sx={{
                            ...theme.typography.mediumAvatar,
                            margin: "8px 0 8px 8px !important",
                            cursor: "pointer"
                        }}
                        ref={anchorRef}
                        aria-controls={open ? "menu-list-grow" : undefined}
                        aria-haspopup="true"
                        color="inherit"
                    />
                }
                label={<IconSettings stroke={1.5} size="1.5rem" color={theme.palette.primary.main} />}
                variant="outlined"
                ref={anchorRef}
                aria-controls={open ? "menu-list-grow" : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
                color="primary"
            />
            <Popper
                placement="bottom-end"
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: "offset",
                            options: {
                                offset: [0, 14]
                            }
                        }
                    ]
                }}
            >
                {({TransitionProps}) => (
                    <Transitions in={open} {...TransitionProps}>
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>
                                    <Box sx={{p: 2}}>
                                        <List
                                            component="nav"
                                            sx={{
                                                width: "100%",
                                                maxWidth: 300,
                                                minWidth: 250,
                                                padding: 0,
                                                backgroundColor: theme.palette.background.paper,
                                                borderRadius: "10px",
                                                [theme.breakpoints.down("md")]: {
                                                    minWidth: "100%"
                                                },
                                                "& .MuiListItemButton-root": {
                                                    mt: 0.5
                                                }
                                            }}
                                        >
                                            {/* <ListItemButton
                                                sx={{borderRadius: `${customization.borderRadius}px`}}
                                                selected={selectedIndex === 0}
                                                onClick={(event) => handleListItemClick(event, 0, "/user/account-profile/profile1")}
                                            >
                                                <ListItemIcon>
                                                    <IconSettings stroke={1.5} size="1.3rem" />
                                                </ListItemIcon>
                                                <ListItemText primary={<Typography>Account Settings</Typography>} />
                                            </ListItemButton> */}
                                            {/* <ListItemButton
                                                sx={{
                                                    borderRadius: `${customization.borderRadius}px`,
                                                    "&:hover p": {
                                                        color: theme.palette.background.paper
                                                    }
                                                }}
                                                selected={selectedIndex === 1}
                                                onClick={(event) => handleListItemClick(event, 1, "/user/social-profile/posts")}
                                            >
                                                <ListItemIcon>
                                                    <IconUser stroke={1.5} size="1.3rem" />
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={
                                                        <Grid container spacing={1} justifyContent="space-between">
                                                            <Grid item>
                                                                <Typography color="inherit">Social Profile</Typography>
                                                            </Grid>
                                                            <Grid item>
                                                                <Chip
                                                                    label="02"
                                                                    size="small"
                                                                    sx={{
                                                                        bgcolor: theme.palette.warning.dark,
                                                                        color: theme.palette.background.default
                                                                    }}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    }
                                                />
                                            </ListItemButton> */}
                                            {/* <ListItemButton
                                                sx={{borderRadius: `${customization.borderRadius}px`}}
                                                selected={selectedIndex === 4}
                                                onClick={handleLangauage}
                                            >
                                                <ListItemIcon>
                                                    <IconLogout stroke={1.5} size="1.3rem" /> 
                                                    <GTranslateIcon />
                                                </ListItemIcon>
                                                <ListItemText primary={<Typography>Switch</Typography>} />
                                            </ListItemButton> */}
                                            <ListItemButton
                                                sx={{borderRadius: `${customization.borderRadius}px`}}
                                                selected={selectedIndex === 4}
                                                onClick={handleLogout}
                                            >
                                                <ListItemIcon>
                                                    <IconLogout stroke={1.5} size="1.3rem" />
                                                </ListItemIcon>
                                                <ListItemText primary={<Typography>Logout</Typography>} />
                                            </ListItemButton>
                                        </List>
                                    </Box>
                                </MainCard>
                            </ClickAwayListener>
                        </Paper>
                    </Transitions>
                )}
            </Popper>
        </>
    );
};

export default ProfileSection;
