import {combineReducers} from "redux";
import adminReducer from "./adminReducer";

// reducer import
import customizationReducer from "./customizationReducer";

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    customization: customizationReducer,
    admin: adminReducer
});

export default reducer;
