function consoleData(key, data) {
    console.log(key, data);
    return;
}

function consoleError(key, data) {
    console.error(key, data);
    return;
}
export {consoleData, consoleError};
