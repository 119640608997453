// project imports
import config from "config";
import {userRole} from "utils/FormatFuncions";

// action - state management
import * as actionTypes from "./actions";
const JWT = JSON.parse(window.localStorage.getItem("jwt")) ?? false;

export const initialState = {
    isLoggedin: JWT,
    dashboardValues: false,
    dashboardCount: false,
    loggedUserRole: userRole?.roleId,
    isOpen: [], // for active default menu
    contacts: [],
    technicians: [],
    configs: [],
    roles: [],
    admins: [],
    customers: [],
    addresslist: [],
    addresstype: [],
    dashboard: [],
    categories: [],
    operationManagers: [],
    fontFamily: config.fontFamily,
    borderRadius: config.borderRadius,
    managerTasks: [],
    countries: [],
    cities: [],
    opened: true
};

const adminReducer = (state = initialState, action) => {
    let id;
    switch (action.type) {
        case actionTypes.MENU_OPEN:
            id = action.id;
            return {
                ...state,
                isOpen: [id]
            };
        case actionTypes.SET_AUTH:
            return {
                ...state,
                isLoggedin: action.isLoggedin,
                loggedUserRole: action.loggedUserRole
            };
        case actionTypes.SET_CONTRACTS:
            return {
                ...state,
                contracts: action.contracts
            };
        case actionTypes.SET_TECHNICIANS:
            return {
                ...state,
                technicians: action.technicians
            };
        case actionTypes.SET_ROLES:
            return {
                ...state,
                roles: action.roles
            };
        case actionTypes.SET_ADMINS:
            return {
                ...state,
                admins: action.admins
            };
        case actionTypes.SET_CUSTOMERS:
            return {
                ...state,
                customers: action.customers
            };
        case actionTypes.SET_USERADDRESS_LIST:
            return {
                ...state,
                addresslist: action.addresslist
            };
        case actionTypes.SET_ADDRESS_TYPE:
            return {
                ...state,
                addresstype: action.addresstype
            };
        case actionTypes.SET_DASHBOARD:
            return {
                ...state,
                dashboard: action.dashboard
            };
        case actionTypes.SET_CATEGORIES:
            return {
                ...state,
                categories: action.categories
            };
        case actionTypes.SET_TASKS:
            return {
                ...state,
                managerTasks: action.managerTasks
            };
        case actionTypes.SET_OPERATION_MANAGERS:
            return {
                ...state,
                operationManagers: action.operationManagers
            };
        case actionTypes.SET_DASHBOARD_VALUES:
            return {
                ...state,
                dashboardValues: action.dashboardValues
            };
        case actionTypes.SET_DASHBOARD_COUNT:
            return {
                ...state,
                dashboardCount: action.dashboardCount
            };
        case actionTypes.SET_COUNTRIES:
            return {
                ...state,
                countries: action.countries
            };
        case actionTypes.SET_CITIES:
            return {
                ...state,
                cities: action.cities
            };
        case actionTypes.SET_CONFIGS:
            return {
                ...state,
                configs: action.configs
            };
        case actionTypes.SET_FONT_FAMILY:
            return {
                ...state,
                fontFamily: action.fontFamily
            };
        case actionTypes.SET_BORDER_RADIUS:
            return {
                ...state,
                borderRadius: action.borderRadius
            };

        default:
            return state;
    }
};

export default adminReducer;
