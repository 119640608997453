import {Box, Card, Typography} from "@mui/material";
import React from "react";

export default function Page({title, children}) {
    // console.log("iiiiiiiiiii", title, props);
    return (
        <Card sx={{m: 1, p: 3, borderColor: "#1565C0", minHeight: "100%"}} variant="outlined">
            {!!title && (
                <Typography variant="h3" component={"div"} gutterBottom>
                    {title}
                </Typography>
            )}
            <Box sx={{mt: 1}}>{children}</Box>
        </Card>
    );
}
