import {useSelector} from "react-redux";
import * as React from "react";
import {ThemeProvider, useTheme, createTheme} from "@mui/material/styles";
import {CssBaseline, StyledEngineProvider} from "@mui/material";
import {SnackbarProvider} from "notistack";
import * as locales from "@mui/material/locale";

// routing
import Routes from "routes";

// defaultTheme
import themes from "themes";

// project imports
import NavigationScroll from "layout/NavigationScroll";
import RTL from "cache";

// ==============================|| APP ||============================== //

const App = () => {
    const customization = useSelector((state) => state.customization);

    const [locale, setLocale] = React.useState(customization?.language ? "arEG" : "enUS");

    const theme = useTheme();

    const themeWithLocale = React.useMemo(() => createTheme(theme, locales[locale]), [locale, theme]);
    const mainValue = {...customization, ...themeWithLocale};

    return (
        <StyledEngineProvider injectFirst>
                <ThemeProvider theme={themeWithLocale}>
                    <ThemeProvider theme={themes(customization)}>
                        <CssBaseline />
                        <div dir={customization?.language ? "rtl" : "ltr"}>
                            <SnackbarProvider maxSnack={3}>
                                <NavigationScroll>
                                    <Routes />
                                </NavigationScroll>
                            </SnackbarProvider>
                        </div>
                    </ThemeProvider>
                </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default App;
