/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect} from "react";
import TdTable from "ui-component/TdTable";
import StatusOne from "ui-component/TdStatus";
import {GridActionsCellItem} from "@mui/x-data-grid";
import MainCard from "ui-component/cards/MainCard";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import {useDispatch, useSelector} from "react-redux";
import axiosClient from "utils/axiosClient";
import {SET_CONTRACTS, SET_COUNTRIES, SET_ROLES} from "store/actions";
import {useSnackbar} from "notistack";
import {Button, Container, Box, Stack, Typography} from "@mui/material";
import {Link, useNavigate} from "react-router-dom";
import {useState} from "react";
import LoadingScreen from "components/loader/LoadingScreen";
import TdSearch from "ui-component/TdSearch";
import ExportButton from "components/utils/ExportButton";

const CountriesTable = () => {
    const navigate = useNavigate();
    const {countries, roles} = useSelector((state) => state?.admin);
    const dispatch = useDispatch();
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const [loading, setLoading] = useState(false);

    //pagination
    const [pageSize, setPageSize] = useState(10);
    const [userCategories, setUserCategories] = useState(roles);
    const [filterName, setFilterName] = useState("");

    const handleFilterByName = async (event) => {
        const {value} = event.target;
        setFilterName(value);
        if (value) {
            const data = userCategories.filter((contracts) => contracts.name.toLowerCase().includes(value));

            setUserCategories(data);
        } else {
            // setFilterName("");
            setUserCategories(roles);
        }
    };

    function clearSearch() {
        setFilterName("");
        setUserCategories(roles);
    }

    const onPageSizeChange = (pageSize) => {
        setPageSize(pageSize);
    };
    const onPageChange = (page) => {
        console.log("Page", page);
    };

    useEffect(() => {
        setLoading(true);
        axiosClient
            .get("/country?pageNo=1&size=100")
            .then(async (res) => {
                if (res.status === 200) {
                    await dispatch({type: SET_COUNTRIES, countries: res.data.data});

                    setUserCategories(res.data.data);
                    setLoading(!true);
                }
            })
            .catch((error) => {
                setLoading(!true);
                if (error?.response?.data?.message) {
                    enqueueSnackbar(error?.response?.data?.message, {variant: "error"});
                }
            });
    }, [dispatch]);

    const handleDelete = (id) => {
        setLoading(true);

        axiosClient
            .delete(`/country/deletecountry?id=${id}`)
            .then(async (res) => {
                if (res.status === 200) {
                    enqueueSnackbar(res.data?.message, {variant: "success"});
                    const filtered = await countries.filter((item) => item?.id !== id);
                    await dispatch({type: SET_COUNTRIES, countries: filtered});
                    setLoading(!true);
                }
            })
            .catch((error) => {
                setLoading(!true);
                if (error?.response?.data?.message) {
                    enqueueSnackbar(error?.response?.data?.message, {variant: "error"});
                }
            });
    };

    const columns = [
        {
            field: "name",
            headerName: "Name",
            disableColumnMenu: true,
            width: 200,
            flex: 1,
            renderCell: (params) => {
                return (
                    <Typography onClick={() => navigate(`/dashboard/view?type=categories&categoryid=${params?.row?.id}`)}>
                        {params?.row?.countryName}
                    </Typography>
                );
            }
        },
        {
            field: "activeStatus",
            headerName: "Status",
            flex: 1,
            disableColumnMenu: true,
            width: 200,
            renderCell: (params) => {
                return (
                    <StatusOne
                        onClick={() => navigate(`/dashboard/view?type=categories&categoryid=${params?.row?.id}`)}
                        value={params?.row?.activeStatus}
                    />
                );
            }
        },
        // {
        //     field: "createdat",
        //     headerName: "Created At",
        //     flex: 1,
        //     disableColumnMenu: true,
        //     width: 200,

        //     renderCell: (params) => {
        //         return (
        //             <Typography onClick={() => navigate(`/dashboard/view?type=categories&categoryid=${params?.row?.id}`)}>
        //                 {new Date(params?.row?.createdDate)?.toLocaleDateString()}
        //             </Typography>
        //         );
        //     }
        // },
        // {
        //     field: "activeStatus",
        //     headerName: "Status",
        //     disableColumnMenu: true,
        //     width: 200,
        //     renderCell: (params) => {
        //         return <StatusOne value={params.row.activeStatus} />;
        //     }
        // },
        // {
        //     field: "isDeleted",
        //     headerName: "Deleted",
        //     disableColumnMenu: true,
        //     width: 200,
        //     renderCell: (params) => {
        //         return <StatusOne value={params.row.isDeleted} />;
        //     }
        // },

        {
            field: "actions",
            headerName: "Actions",
            type: "actions",
            width: 80,
            disableColumnMenu: true,
            getActions: (params) => [
                <GridActionsCellItem
                    icon={<DeleteOutlineOutlinedIcon fontSize="small" />}
                    label="Delete"
                    onClick={() => handleDelete(params?.row?.id)}
                    showInMenu
                />,
                <GridActionsCellItem
                    icon={<EditOutlinedIcon fontSize="small" />}
                    label="Edit"
                    onClick={async () => {
                        //  await window.localStorage.setItem("rolename", JSON.stringify(params?.row));
                        navigate(`/dashboard/countries/create?edit=${params?.row?.id}`);
                    }}
                    showInMenu
                />,
                <GridActionsCellItem
                    icon={<RemoveRedEyeOutlinedIcon fontSize="small" />}
                    label="View"
                    onClick={() => navigate(`/dashboard/view?type=categories&categoryid=${params?.row?.id}`)}
                    showInMenu
                />
                // <GridActionsCellItem
                //     icon={<RemoveRedEyeOutlinedIcon fontSize="small" />}
                //     label="View"
                //     onClick={() => console.log("PARAMS", params)}
                //     showInMenu
                // />
            ]
        }
    ];
    return (
        <MainCard
            title="Countries"
            border={false}
            secondary={
                <Stack direction="row" alignItems="center" spacing={2}>
                    <Button
                        sx={{my: 1}}
                        variant="contained"
                        component={Link}
                        to={"/dashboard/countries/create"}
                        disableElevation
                        color="secondary"
                    >
                        Create Country
                    </Button>
                    <ExportButton fileName={"categories.xlsx"} arrayData={roles} />
                </Stack>
            }
        >
            {loading ? (
                <LoadingScreen />
            ) : (
                !loading &&
                countries?.length > 0 && (
                    <>
                        <TdSearch searchTerm={filterName} clearSearch={clearSearch} onHandleSearch={handleFilterByName} />
                        <TdTable
                            columns={columns}
                            rows={userCategories}
                            disableColumnSelector={true}
                            pagination={true}
                            rowsPerPageOptions={[10, 25, 50]}
                            rowsCount={14}
                            onPageSizeChange={onPageSizeChange}
                            pageSize={pageSize}
                            onPageChange={onPageChange}
                            hideFooter={false}
                        />
                    </>
                )
            )}
        </MainCard>
    );
};

export default CountriesTable;
