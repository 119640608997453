import {Button, FormControl, FormControlLabel, Switch, TextField} from "@mui/material";
import {Box, Container} from "@mui/system";
import {Form, FormikProvider, useFormik} from "formik";
import Page from "layout/Page";
import {useSnackbar} from "notistack";
import React, {useEffect} from "react";
import {useNavigate} from "react-router";
import {useSearchParams} from "react-router-dom";
import axiosClient from "utils/axiosClient";
import MainCard from "ui-component/cards/MainCard";
import * as Yup from "yup";
import {useState} from "react";
import LoadingScreen from "components/loader/LoadingScreen";
import {MRadio, MTextField} from "lib";
import activeStatusText from "data/ActiveStatusText";
import {consoleError} from "utils/consoleData";

export default function CreateCountry() {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    //  const getRoleName = JSON.parse(window.localStorage.getItem("rolename"));
    const [loading, setLoading] = useState(false);
    const [singleCategory, setSingleCategory] = useState();

    // fetch individual category values
    const id = searchParams.get("edit");

    useEffect(() => {
        if (window.location.href.includes("edit")) {
            setLoading(true);
            axiosClient
                .get(`/country/getcountrylistbyid?id=${searchParams.get("edit")}`)
                .then((res) => {
                    setSingleCategory(res.data.data);
                    setLoading(!true);
                })
                .catch((err) => {
                    consoleError("error", err);
                    setLoading(!true);
                });
        }
    }, [id, searchParams]);

    const handleRole = (payload) => {
        setLoading(true);
        if (window.location.href.includes("edit")) {
            axiosClient
                .put(`/country/updatecountry?id=${searchParams.get("edit")}`, payload)
                .then((res) => {
                    enqueueSnackbar(res.data?.message, {variant: "success"});
                    setTimeout(() => {
                        navigate("/dashboard/countries");
                    }, 1000);
                    setLoading(!true);
                })
                .catch((err) => {
                    enqueueSnackbar(err?.message, {variant: "error"});
                    consoleError("error", err);
                    setLoading(!true);
                });
        } else {
            setLoading(true);
            let data = {
                name: payload.name,
                languages: [
                    {
                        languageId: 1,
                        name: payload.name
                    }
                ]
            };
            axiosClient
                .post("/country/addcountry", data)
                .then((res) => {
                    enqueueSnackbar(res.data?.message, {variant: "success"});
                    setLoading(!true);
                    setTimeout(() => {
                        navigate("/dashboard/countries");
                    }, 1000);
                })
                .catch((err) => {
                    consoleError("error", err);
                    enqueueSnackbar(err?.message, {variant: "error"});
                    setLoading(!true);
                });
        }
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: singleCategory?.countryName ?? "",
            activeStatus: singleCategory?.activeStatus ?? true
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().trim().min(3).required("Country Name is required."),
            activeStatus: Yup.bool().required()
        }),
        onSubmit: (values, {resetForm}) => {
            handleRole(values);
        }
    });
    return (
        <Container maxWidth="sm">
            <MainCard title={searchParams.get("edit") ? "Update Country" : `Create Country`} border={false}>
                {loading ? (
                    <LoadingScreen />
                ) : (
                    !loading && (
                        <FormikProvider value={formik}>
                            <Form noValidate onSubmit={formik.handleSubmit}>
                                <Box>
                                    <MTextField label="Country Name" name="name" />

                                    <MRadio
                                        name="activeStatus"
                                        label="Status"
                                        legend={"Status"}
                                        options={activeStatusText}
                                        value={formik.values.activeStatus}
                                    />

                                    <Button type="submit" variant="contained" fullWidth sx={{mt: 3, mb: 2}}>
                                        submit
                                    </Button>
                                </Box>
                            </Form>
                        </FormikProvider>
                    )
                )}
            </MainCard>
        </Container>
    );
}
