import dashboard from "./dashboard";
import pages from "./pages";
import utilities from "./utilities";

// ==============================|| MENU ITEMS ||============================== //
// pages,
const menuItems = {
    items: [dashboard, utilities]
};

export default menuItems;
