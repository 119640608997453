import {lazy} from "react";

// project imports
import MainLayout from "layout/MainLayout";
import Loadable from "ui-component/Loadable";
import {RequireAuth} from "utils/RequireAuth";
import CategoriesTable from "views/pages/dashBoard/CategoryTable";
import CreateCategory from "components/categories/CreateCategory";
import CreateCustomer from "components/customers/CreateCustomer";
import CreateOperationManager from "components/operationManagers/CreateOperationManager";
import CreateCity from "components/countries_cities/CreateCity";
import CreateCountry from "components/countries_cities/CreateCountry";
import CountriesTable from "views/pages/dashBoard/CountriesTable";

// import SecondCreateContract from "components/contracts/SecondCreateContract";

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import("views/dashboard/Default")));
const RolesTable = Loadable(lazy(() => import("views/pages/dashBoard/RolesTable")));
const AdminUserTable = Loadable(lazy(() => import("views/pages/dashBoard/AdminUserTable")));
const CreateRole = Loadable(lazy(() => import("components/roles/CreateRole")));
const AdminTable = Loadable(lazy(() => import("views/pages/dashBoard/AdminTable")));
const CreateAdmin = Loadable(lazy(() => import("components/admins/CreateAdmin")));
const CreateAdminUser = Loadable(lazy(() => import("components/adminusers/CreateAdminUser")));
const TechnicianTask = Loadable(lazy(() => import("components/technicians/TechnicianTask")));
// utilities routing
const UtilsTypography = Loadable(lazy(() => import("views/utilities/Typography")));
const UtilsColor = Loadable(lazy(() => import("views/utilities/Color")));
const UtilsShadow = Loadable(lazy(() => import("views/utilities/Shadow")));
const UtilsMaterialIcons = Loadable(lazy(() => import("views/utilities/MaterialIcons")));
const UtilsTablerIcons = Loadable(lazy(() => import("views/utilities/TablerIcons")));
const TableComponent = Loadable(lazy(() => import("views/pages/dashBoard/Table")));
const ContractTable = Loadable(lazy(() => import("views/pages/dashBoard/ContactTable")));
const CreateContact = Loadable(lazy(() => import("components/contracts/CreateContract")));
const TechnicianTable = Loadable(lazy(() => import("views/pages/dashBoard/TechnicianTable")));
const CreateTechnicians = Loadable(lazy(() => import("components/technicians/CreateTechnicians")));

const CustomersTable = Loadable(lazy(() => import("views/pages/dashBoard/CustomersTable")));
const UserAddressListTable = Loadable(lazy(() => import("views/pages/dashBoard/UserAddressList")));
const CreateAddressList = Loadable(lazy(() => import("components/addresslists/CreateAddressList")));
const UserTracking = Loadable(lazy(() => import("views/pages/dashBoard/UserTracking")));
const ViewPage = Loadable(lazy(() => import("components/contracts/ViewPage")));

const OperationManager = Loadable(lazy(() => import("views/pages/dashBoard/OperationManager")));
const OperationManagerTable = Loadable(lazy(() => import("views/pages/dashBoard/OperationManagerTable.js")));

// configure points page
const CreateConfig = Loadable(lazy(() => import("components/configure_points/CreateConfigurePoints.js")));
const ConfigPoints = Loadable(lazy(() => import("views/pages/dashBoard/ConfigPoints.js")));
// commons
const NotFound = Loadable(lazy(() => import("views/pages/common/NotFound.js")));

// sample page routing
const SamplePage = Loadable(lazy(() => import("views/sample-page")));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: "/",
    element: (
        <RequireAuth>
            <MainLayout />
        </RequireAuth>
    ),
    children: [
        {path: "*", element: <NotFound />},
        {
            path: "/",
            element: <DashboardDefault />
        },
        {
            path: "dashboard",
            children: [
                {
                    path: "default",
                    element: <DashboardDefault />
                }
            ]
        },
        {
            path: "dashboard",
            children: [
                {
                    path: "util-typography",
                    element: <UtilsTypography />
                }
            ]
        },
        {
            path: "dashboard",
            children: [
                {
                    path: "util-color",
                    element: <UtilsColor />
                }
            ]
        },
        {
            path: "dashboard",
            children: [
                {
                    path: "util-shadow",
                    element: <UtilsShadow />
                }
            ]
        },
        {
            path: "icons",
            children: [
                {
                    path: "tabler-icons",
                    element: <UtilsTablerIcons />
                }
            ]
        },
        {
            path: "icons",
            children: [
                {
                    path: "material-icons",
                    element: <UtilsMaterialIcons />
                }
            ]
        },
        {
            path: "sample-page",
            element: <SamplePage />
        },
        {
            path: "dashboard",
            children: [
                {
                    path: "util-table",
                    element: <TableComponent />
                },
                {
                    path: "contracts",
                    element: <ContractTable />
                },
                {
                    path: "contracts/create",
                    element: <CreateContact />
                    //  element: <SecondCreateContract />
                },
                {
                    path: "view",
                    element: <ViewPage />
                }
            ]
        },
        {
            path: "dashboard",
            children: [
                {
                    path: "technicians",
                    element: <TechnicianTable />
                },
                {
                    path: "technicians/create",
                    element: <CreateTechnicians />
                },
                {
                    path: "technicians/tasks",
                    element: <TechnicianTask />
                }
            ]
        },
        {
            path: "dashboard/roles",
            children: [
                {
                    path: "",
                    element: <RolesTable />
                },
                {
                    path: "create",
                    element: <CreateRole />
                }
            ]
        },
        {
            path: "dashboard/categories",
            children: [
                {
                    path: "",
                    element: <CategoriesTable />
                },
                {
                    path: "create",
                    element: <CreateCategory />
                }
            ]
        },
        {
            path: "dashboard/admins",
            children: [
                {
                    path: "",
                    element: <AdminTable />
                },
                {
                    path: "create",
                    element: <CreateAdmin />
                },
                {
                    path: "technicians/tasks",
                    element: <TechnicianTask />
                }
            ]
        },
        {
            path: "dashboard/adminusers",
            children: [
                {
                    path: "",
                    element: <AdminUserTable />
                },
                {
                    path: "create",
                    element: <CreateAdminUser />
                }
            ]
        },
        {
            path: "dashboard/customers",
            children: [
                {
                    path: "",
                    element: <CustomersTable />
                },
                {
                    path: "create",
                    element: <CreateCustomer />
                }
            ]
        },
        {
            path: "dashboard/addresslist",
            children: [
                {
                    path: "",
                    element: <UserAddressListTable />
                },
                {
                    path: "create",
                    element: <CreateAddressList />
                }
            ]
        },
        {
            path: "dashboard/usertracking",
            children: [
                {
                    path: "",
                    element: <UserTracking />
                }
            ]
        },
        {
            path: "dashboard/operationmanager",
            children: [
                {
                    path: "",
                    //  element: <OperationManagerTable />
                    element: <OperationManager />
                },
                {
                    path: "create",
                    element: <CreateOperationManager />
                },
                {
                    path: "lists",
                    element: <OperationManagerTable />
                }
            ]
        },
        {
            path: "dashboard/countries",
            children: [
                {
                    path: "",
                    //  element: <OperationManagerTable />
                    element: <CountriesTable />
                },
                {
                    path: "create",
                    element: <CreateCountry />
                },
                {
                    path: "lists",
                    element: <OperationManagerTable />
                }
            ]
        },
        {
            path: "dashboard/cities",
            children: [
                {
                    path: "",
                    //  element: <OperationManagerTable />
                    element: <CountriesTable />
                },
                {
                    path: "create",
                    element: <CreateCity />
                },
                {
                    path: "lists",
                    element: <OperationManagerTable />
                }
            ]
        },
        {
            path: "dashboard/config",
            children: [
                {
                    path: "",
                    //  element: <OperationManagerTable />
                    // element: <ConfigPoints />
                    element: <CreateConfig />
                }
                // {
                //     path: "create",
                //     element: <CreateConfig />
                // },
            ]
        }
    ]
};

export default MainRoutes;
