import {Box} from "@mui/material";

const StatusOne = ({ value }) => {
	return (
		<Box
			sx={{
				padding: " 3px 10px",
				bgcolor: value ? "success.dark" : "error.dark",
				color: "#fff",
				borderRadius: "35px",
				display: "inline-block"
			}}
		>
			{value ? "Active" : "Inactive"}
		</Box>
	);
};

export default StatusOne;
