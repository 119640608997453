import PropTypes from "prop-types";

// material-ui
import {useTheme} from "@mui/material/styles";
import {Avatar, Box, ButtonBase} from "@mui/material";

// project imports
import LogoSection from "../LogoSection";
import ProfileSection from "./ProfileSection";
import Language from "./Language";

// assets
import {IconMenu2} from "@tabler/icons";

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = ({handleLeftDrawerToggle}) => {
    const theme = useTheme();

    return (
        <>
            {/* logo & toggler button */}
            <Box
                sx={{
                    width: 228,
                    display: "flex",
                    [theme.breakpoints.down("md")]: {
                        width: "auto"
                    }
                }}
            >
                <Box component="span" sx={{display: {xs: "none", md: "block"}, flexGrow: 1}}>
                    <LogoSection />
                </Box>
                <ButtonBase sx={{borderRadius: "12px", overflow: "hidden"}}>
                    <Avatar
                        variant="rounded"
                        sx={{
                            ...theme.typography.commonAvatar,
                            ...theme.typography.mediumAvatar,
                            transition: "all .2s ease-in-out",
                            background: theme.palette.primary.light,
                            color: theme.palette.primary.dark,
                            width: 48,
                            height: 48,
                            "&:hover": {
                                background: theme.palette.primary.dark,
                                color: theme.palette.primary.light
                            }
                        }}
                        onClick={handleLeftDrawerToggle}
                        color="inherit"
                    >
                        <IconMenu2 stroke={1.5} size="1.7rem" />
                    </Avatar>
                </ButtonBase>
            </Box>

            <Box sx={{flexGrow: 1}} />
            <Box sx={{flexGrow: 1}} />

            {/* notification & profile */}
            {/* <NotificationSection /> */}
            <Language />
            <ProfileSection />
        </>
    );
};

Header.propTypes = {
    handleLeftDrawerToggle: PropTypes.func
};

export default Header;
