const Roles = [
    {},
    {
        name: "admin",
        id: 1
    },
    {
        name: "operation_manager",
        id: 2
    },
    {
        name: "technician",
        id: 3
    },
    {
        name: "contract_user",
        id: 4
    },
    {
        name: "guest_user",
        id: 5
    }
];

export default Roles;
