import * as React from "react";
import {TextField, Box} from "@mui/material";
import {IconSearch, IconX} from "@tabler/icons";

const TdSearch = (props) => {
    const {searchTerm, clearSearch, onHandleSearch} = props;

    return (
        <Box sx={{marginBottom: "25px", width: "100%", maxWidth: "320px"}}>
            <TextField
                fullWidth
                placeholder="Search"
                value={searchTerm}
                InputProps={{
                    startAdornment: <IconSearch style={{marginRight: "5px"}} />,
                    endAdornment: searchTerm && <IconX style={{cursor: "pointer"}} onClick={clearSearch} />
                }}
                onChange={onHandleSearch}
            />
        </Box>
    );
};

export default TdSearch;
