import {Box, CircularProgress} from "@mui/material";
import React, {Fragment} from "react";

export default function LoadingScreen() {
    return (
        <Fragment>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    minHeight: "60vh"
                }}
            >
                <CircularProgress size={70} disableShrink />
            </Box>
        </Fragment>
    );
}
