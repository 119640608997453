// assets
import {
    IconShadow,
    IconBriefcase,
    IconRecycle,
    IconAffiliate,
    IconUserCheck,
    IconUserPlus,
    IconMap,
    IconMapPin,
    IconTool
} from "@tabler/icons";
import {userRole} from "utils/FormatFuncions";

// constant
const icons = {
    IconBriefcase,
    IconShadow,
    IconRecycle,
    IconAffiliate,
    IconUserCheck,
    IconUserPlus,
    IconMap,
    IconMapPin,
    IconTool
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const utilities = {
    id: "utilities",
    title: "Utilities",
    type: "group",
    children: [
        // {
        //     id: "util-typography",
        //     title: "Typography",
        //     type: "item",
        //     url: "/dashboard/util-typography",
        //     icon: icons.IconTypography,
        //     breadcrumbs: false
        // },
        // {
        //     id: "util-color",
        //     title: "Color",
        //     type: "item",
        //     url: "/dashboard/util-color",
        //     icon: icons.IconPalette,
        //     breadcrumbs: false
        // },
        // {
        //     id: "util-shadow",
        //     title: "Shadow",
        //     type: "item",
        //     url: "/dashboard/util-shadow",
        //     icon: icons.IconShadow,
        //     breadcrumbs: false
        // },
        // {
        //     id: "icons",
        //     title: "Icons",
        //     type: "collapse",
        //     icon: icons.IconWindmill,
        //     children: [
        //         {
        //             id: "tabler-icons",
        //             title: "Tabler Icons",
        //             type: "item",
        //             url: "/icons/tabler-icons",
        //             breadcrumbs: false
        //         },
        //         {
        //             id: "material-icons",
        //             title: "Material Icons",
        //             type: "item",
        //             url: "/icons/material-icons",
        //             breadcrumbs: false
        //         }
        //     ]
        // },
        // {
        //     id: "util-table",
        //     title: "Table",
        //     type: "item",
        //     url: "/dashboard/util-table",
        //     icon: icons.IconShadow,
        //     breadcrumbs: false
        // },
        // {
        //     id: "contracts",
        //     title: "Contracts",
        //     type: "item",
        //     url: "/dashboard/contracts",
        //     icon: icons.IconBriefcase,
        //     breadcrumbs: false,
        //     display: userRole?.roleId === 1 ? true : false
        // },

        {
            id: "technicians",
            title: "Technicians",
            type: "item",
            url: "/dashboard/technicians",
            icon: icons.IconRecycle,
            breadcrumbs: false,
            display: userRole?.roleId === 1 ? true : userRole?.roleId === 3 ? true : false
        },
        // {
        //     id: "roles",
        //     title: "Roles",
        //     type: "item",
        //     url: "/dashboard/roles",
        //     icon: icons.IconAffiliate,
        //     breadcrumbs: false,
        // display: userRole?.roleId === 1 ? true : false
        // },
        {
            id: "category",
            title: "Categories",
            type: "item",
            url: "/dashboard/categories",
            icon: icons.IconAffiliate,
            breadcrumbs: false,
            display: userRole?.roleId === 1 ? true : false
        },
        {
            id: "admins",
            title: "Admins",
            type: "item",
            url: "/dashboard/admins",
            icon: icons.IconUserCheck,
            breadcrumbs: false,
            display: userRole?.roleId === 1 ? true : false
        },
        // {
        //     id: "adminusers",
        //     title: "Admin User",
        //     type: "item",
        //     url: "/dashboard/adminusers",
        //     icon: icons.IconShadow,
        //     breadcrumbs: false,
        // display: userRole?.roleId === 1 ? true : false,
        // },
        {
            id: "customers",
            title: "Customers",
            type: "item",
            url: "/dashboard/customers",
            icon: icons.IconUserPlus,
            breadcrumbs: false,
            display: userRole?.roleId === 1 ? true : false
        },
        // {
        //     id: "addresslist",
        //     title: "Address Lists",
        //     type: "item",
        //     url: "/dashboard/addresslist",
        //     icon: icons.IconMap,
        //     breadcrumbs: false,
        //     display: userRole?.roleId === 1 ? true : false
        // },

        // {
        //     id: "UserTracking",
        //     title: "User Tracking",
        //     type: "item",
        //     url: "/dashboard/usertracking",
        //     icon: icons.IconMapPin,
        //     breadcrumbs: false,
        //     display: userRole?.roleId === 1 ? true : false
        // },
        {
            id: "Operationmanager",
            title: "Tasks Manager",
            type: "item",
            url: "/dashboard/operationmanager",
            icon: icons.IconTool,
            breadcrumbs: false,
            display: userRole?.roleId === 1 ? true : userRole?.roleId === 3 ? true : false
        },
        {
            id: "configs",
            title: "Configuration",
            type: "item",
            url: "/dashboard/config",
            icon: icons.IconRecycle,
            breadcrumbs: false,
            display: userRole?.roleId === 1 ? true : userRole?.roleId === 3 ? true : false
        },
        {
            id: "Countries",
            title: "Country",
            type: "item",
            url: "/dashboard/countries",
            icon: icons.IconTool,
            breadcrumbs: false,
            display: userRole?.roleId === 1 ? true : userRole?.roleId === 3 ? true : false
        }
        // {
        //     id: "Cities",
        //     title: "City",
        //     type: "item",
        //     url: "/dashboard/cities",
        //     icon: icons.IconTool,
        //     breadcrumbs: false,
        //     display: userRole?.roleId === 1 ? true : userRole?.roleId === 3 ? true : false
        // }
    ]
};

export default utilities;
