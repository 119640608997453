import PropTypes from "prop-types";
import {useState} from "react";
import {useSelector} from "react-redux";

// material-ui
import {useTheme} from "@mui/material/styles";
import {Collapse, List, ListItemButton, ListItemIcon, ListItemText, Typography} from "@mui/material";

// project imports
import NavItem from "../NavItem";

// assets
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import {IconChevronDown, IconChevronUp} from "@tabler/icons";

// ==============================|| SIDEBAR MENU LIST COLLAPSE ITEMS ||============================== //

const NavCollapse = ({menu, level}) => {
    const theme = useTheme();
    const customization = useSelector((state) => state.customization);

    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState(null);

    const handleClick = () => {
        setOpen(!open);
        setSelected(!selected ? menu.id : null);
    };

    // menu collapse & item
    const menus = menu.children?.map((item) => {
        switch (item.type) {
            case "collapse":
                return <NavCollapse key={item.id} menu={item} level={level + 1} />;
            case "item":
                return <NavItem key={item.id} item={item} level={level + 1} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    const Icon = menu.icon;
    const menuIcon = menu.icon ? (
        <Icon strokeWidth={1.5} size="1.6rem" style={{marginTop: "auto", marginBottom: "auto"}} />
    ) : (
        <FiberManualRecordIcon
            sx={{
                width: selected === menu.id ? 8 : 6,
                height: selected === menu.id ? 8 : 6
            }}
            fontSize={level > 0 ? "inherit" : "medium"}
        />
    );

    return (
        <>
            <ListItemButton
                sx={{
                    borderRadius: `${customization.borderRadius}px`,
                    mb: 0.5,
                    alignItems: "flex-start",
                    backgroundColor: level > 1 ? "transparent !important" : "inherit",
                    py: level > 1 ? 1 : 1.25,
                    pl: `${level * 14}px`
                }}
                selected={selected === menu.id}
                onClick={handleClick}
            >
                <ListItemIcon sx={{my: "auto", minWidth: !menu.icon ? 18 : 36, color: theme.palette.background.paper}}>
                    {menuIcon}
                </ListItemIcon>
                <ListItemText
                    primary={
                        <Typography
                            variant={selected === menu.id ? "h5" : "body1"}
                            color="inherit"
                            sx={{my: "auto", color: theme.palette.background.paper}}
                        >
                            {menu.title}
                        </Typography>
                    }
                    secondary={
                        menu.caption && (
                            <Typography
                                variant="caption"
                                sx={{color: theme.palette.background.paper, ...theme.typography.subMenuCaption}}
                                display="block"
                                gutterBottom
                            >
                                {menu.caption}
                            </Typography>
                        )
                    }
                />
                {open ? (
                    <IconChevronUp
                        stroke={1.5}
                        size="1rem"
                        style={{marginTop: "auto", marginBottom: "auto", color: theme.palette.background.paper}}
                    />
                ) : (
                    <IconChevronDown
                        stroke={1.5}
                        size="1rem"
                        style={{marginTop: "auto", marginBottom: "auto", color: theme.palette.background.paper}}
                    />
                )}
            </ListItemButton>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List
                    component="div"
                    disablePadding
                    sx={{
                        position: "relative"
                    }}
                >
                    {menus}
                </List>
            </Collapse>
        </>
    );
};

NavCollapse.propTypes = {
    menu: PropTypes.object,
    level: PropTypes.number
};

export default NavCollapse;
