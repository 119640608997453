import React from "react";
import {Menu, MenuItem} from "@mui/material";

const TdTableActionMenu = (props) => {
    const {open, selectedActionRow, rowActions, handleClose, anchorEl} = props;

    return (
        <div>
            <Menu data-testid="MiTable-Menu" anchorEl={anchorEl} onClose={handleClose} open={open}>
                {rowActions.map((action, index) => {
                    return (
                        <MenuItem
                            disabled={action.disabled}
                            data-testid={`MiTable-MenuItem-${index}`}
                            onClick={() => action.onClick(selectedActionRow)}
                            key={index}
                        >
                            <div style={{display: "flex", alignItems: "center"}}>
                                {action.renderIcon && <div style={{marginRight: 10}}>{action.renderIcon(selectedActionRow)}</div>}
                                {action.text}
                            </div>
                        </MenuItem>
                    );
                })}
            </Menu>
        </div>
    );
};
export default TdTableActionMenu;
