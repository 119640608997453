import {Fragment} from "react";
import {useSelector} from "react-redux";
import {Navigate} from "react-router-dom";

export const RequireAuth = ({children}) => {
    const {isLoggedin} = useSelector((state) => state?.admin);

    if (!isLoggedin) {
        return <Navigate to="/login" replace={true} />;
    }
    return children;
};
