import {createTheme, useTheme} from "@mui/material/styles";

// assets
import colors from "assets/scss/_themes-vars.module.scss";

// project imports
import componentStyleOverrides from "./compStyleOverride";
import themePalette from "./palette";
import themeTypography from "./typography";
import * as locales from "@mui/material/locale";
import {useSelector} from "react-redux";
import {enUS, arEG} from "@mui/material/locale";

/**
 * Represent theme style and structure as per Material-UI
  param {JsonObject} customization customization parameter object
 */

export const theme = (customization) => {
    const color = colors;

    const themeOption = {
        colors: color,
        heading: color.grey900,
        paper: color.paper,
        backgroundDefault: color.paper,
        background: color.primaryLight,
        darkTextPrimary: color.grey700,
        darkTextSecondary: color.grey500,
        textDark: color.grey900,
        menuSelected: color.paper,
        menuSelectedBack: color.secondaryMain,
        divider: color.grey200,
        customization
    };

    const themeOptions = {
        direction: customization?.language ? "rtl" : "ltr",
        palette: themePalette(themeOption),
        mixins: {
            toolbar: {
                minHeight: "48px",
                padding: "16px",
                "@media (min-width: 600px)": {
                    minHeight: "48px"
                }
            }
        },
        typography: themeTypography(themeOption)
    };

    const themes = createTheme(themeOptions, customization?.language ? arEG : enUS);
    themes.components = componentStyleOverrides(themeOption);

    return themes;
};

export default theme;
