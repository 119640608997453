const MaritalStatusText = [
    {
        name: "No",
        value: 0
    },
    {
        name: "Yes",
        value: 1
    }
];

export default MaritalStatusText;
