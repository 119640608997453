import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import {languageValue} from "utils/FormatFuncions";

i18n.use(initReactI18next).init({
    fallbackLng: "en",
    lng: JSON.parse(window.localStorage.getItem("language")) || "en",
    resources: {
        en: {
            translations: require("./en/translations.json")
        },
        ar: {
            translations: require("./ar/translations.json")
        }
    },
    ns: ["translations"],
    defaultNS: "translations"
});

i18n.languages = ["en", "ar"];

export default i18n;
