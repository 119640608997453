var Lat;
var Long;

export const passwordRegExp = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;

export const formatNumber = (value) => {
    const format = new Intl.NumberFormat("en-IN", {style: "currency", currency: "USD"}).format(value);
    return format;
};

export const DateTimeFormatLocal = (value) => {
    const localDateString = new Intl.DateTimeFormat("en-IN", {
        dateStyle: "short",
        timeStyle: "short"
    }).format;
    const mee = localDateString(new Date(value));
    return mee;
};

export const formatTitle = (value) => {
    const result = value.replace(/([A-Z])/g, " $1");
    const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
    return finalResult;
};

export function getCoordinates() {
    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(successFunction, errorFunction);
    } else {
        alert(
            "It seems like Geolocation, which is required for this page, is not enabled in your browser. Please use a browser which supports it."
        );
    }
}

async function successFunction(position) {
    await Lat(position.coords.latitude);
    await Long(position.coords.longitude);
    console.log("Your latitude is :" + Lat + " and longitude is " + Long);
}

function errorFunction(position) {
    Lat(position.coords.latitude);
    Long(position.coords.longitude);
    console.log("Your latitude is :" + Lat + " and longitude is " + Long);
}

function isUrlValid(userInput) {
    var res = userInput.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
    if (res == null) {
        return false;
    } else {
        return true;
    }
}

async function languageValue() {
    const value = JSON.parse(window.localStorage.getItem("language"));
    return value;
}

export const removeObjectValues = (obj) => {
    for (const key in obj) {
        if (obj[key] === null || obj[key] === undefined || obj[key] === "") {
            delete obj[key];
        }
    }

    return obj;
};

export const removeObjectFalseValues = (obj) => {
    for (const key in obj) {
        if (obj[key] === null || obj[key] === undefined || obj[key] === "" || obj[key] === false) {
            delete obj[key];
        }
    }

    return obj;
};

export const validateValue = (value) => {
    if (typeof value == undefined || typeof value == null) {
        let mee = "";
        return mee;
    }
};

export const userRole = JSON.parse(window.localStorage.getItem("user"));

export {Lat, Long, isUrlValid, languageValue};
