import {useState} from "react";
import {Button} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import ArabicFlag from "../../../../assets/images/ar.png";
import EnglishFlag from "../../../../assets/images/en.png";
import {useTranslation} from "react-i18next";
import {SET_LANGUAGE} from "store/actions";

const Language = () => {
    const {t, i18n} = useTranslation();
    const dispatch = useDispatch();
    const customization = useSelector((state) => state.customization);
    const handleLangauage = async () => {
        //  await setOpen(false);
        await i18n.changeLanguage(customization?.language ? "en" : "ar");
        await window.localStorage.setItem("language", JSON.stringify(!customization?.language ? "ar" : "en"));
        await window.localStorage.setItem("direction", JSON.stringify(!customization?.language));
        await dispatch({type: SET_LANGUAGE, language: !customization?.language});
    };
    return (
        <Button sx={{mx: 2}} onClick={handleLangauage}>
            <img src={customization?.language ? EnglishFlag : ArabicFlag} alt="arabic" style={{maxWidth: "40px", height: "auto"}} />
        </Button>
    );
};

export default Language;
